import React from "react"
import * as styles from "./index.module.scss"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"

export const farms = [
  {
    name: "Walapane Farm",
    image: require("./walapane.jpg").default,
    location: "Nuwara Eliya",
    category: "Tieflandgemüse und -früchte",
    certifications: "PGS Zertifiert",
  },
  {
    name: "Horowpathana Farm",
    image: require("./horowpathana.jpg").default,
    location: "Anuradhapura",
    category: "Kokos, Früchte, Gemüse und Gewürze",
    certifications: "Sri Cert Zertifiert",
  },
  {
    name: "Tea Estate",
    image: require("./tea-estate.webp").default,
    location: "Balangoda",
    category: "Low country Gemüse und -früchte",
    certifications: "Control Union Zertifiert",
  },
]

const OurFarmsPage = () => {
  return (
    <>
      <Seo title="Unsere Farmen" />
      <Breadcrumbs title={"Unsere Farmen"} />

      <div className="container py-5">
        {farms.map((farm, idx) => {
          return (
            <div className="row mt-5" key={idx}>
              <div className="col-sm-6 col-lg-7 d-flex align-items-center">
                <div className={styles.farm_image}>
                  <img src={farm.image} alt={farm.name} />
                </div>
              </div>
              <div className="col-sm-6 col-lg-5 d-flex align-items-center">
                <div>
                  <h2>{farm.name}</h2>
                  <p>
                    Standort: {farm.location}, Sri Lanka
                    <br />
                    {farm.certifications}
                    <br />
                    {farm.category}
                  </p>
                  {/*<p>{farm.description}</p>*/}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default OurFarmsPage
